import React from "react";
import { TextField, Button, Box, Link } from "@mui/material";
import Text from "../../common/Text";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../services/storageService";
import "./LoginBanner.css";
import i18n from "../../../config/i18";
// import { useTranslation } from "react-i18next";
const SigninForm = () => {
  // const { t } = useTranslation();
  const [errorMessage] = React.useState(false);
  const [clicksubmit, setClickSubmit] = React.useState(false);
  const navigate = useNavigate();
  const handleSignIn = () => {
    navigate("/services");
  };
  const handleLanguageChange = (val: string) => {
    i18n.changeLanguage(val);
    setLocalStorageItem("languageSelect", val);
    if (val === "en") {
      setClickSubmit(false);
    } else {
      setClickSubmit(true);
    }
  };

  return (
    <div style={{ marginRight: 20 }}>
      <Box>
        <Text fontSize={24} fontWeight={600} style={{ marginTop: 25 }}>
          Welcome to
        </Text>
      </Box>
      <Box
        component={"img"}
        src={`./logo.svg`}
        style={{ marginTop: 15, width: 150 }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            marginBottom: 3,
            marginTop: 20,
          }}
        >
          Username / Email ID
        </Text>

        <Text
          style={{
            marginLeft: 4,
            marginBottom: 4,
          }}
          color="#F13939"
        >
          *
        </Text>
      </Box>

      <TextField
        fullWidth
        size="small"
        placeholder="Enter your registered email id"
        variant="outlined"
      />
      {errorMessage && (
        <Text
          style={{
            justifyContent: "flex-start",
            display: "flex",
            width: "100%",
          }}
          fontSize={12}
          fontWeight={400}
          color="#F13939"
        >
          This email is not registered with MVMH, please Sign Up
        </Text>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            marginBottom: 3,
            marginTop: 25,
          }}
        >
          Password
        </Text>

        <Text
          style={{
            marginLeft: 4,
            marginBottom: 4,
          }}
          color="#F13939"
        >
          *
        </Text>
      </Box>

      <TextField
        fullWidth
        size="small"
        placeholder="Enter your password"
        variant="outlined"
      />
      {errorMessage && (
        <Text
          style={{
            justifyContent: "flex-start",
            display: "flex",
            width: "100%",
          }}
          fontSize={12}
          fontWeight={400}
          color="#F13939"
        >
          This email is not registered with MVMH, please Sign Up
        </Text>
      )}

      <Text
        style={{
          justifyContent: "flex-end",
          display: "flex",
          width: "100%",
        }}
        fontSize={14}
        fontWeight={500}
        color="#910c86"
      >
        Forgot Password?
      </Text>

      <Button
        sx={{
          width: "100%",
          backgroundColor: "#910c86",
          borderRadius: 1,
          textTransform: "none",
          marginTop: 5,
        }}
        className="btn-primary"
        onClick={handleSignIn}
      >
        <Text fontSize={18} fontWeight={600} color="#FFF">
          Sign In
        </Text>
      </Button>
      <Text style={{ marginTop: 15, marginBottom: 15 }}>
        By clicking Sign In, you agree to MVMH Terms of Services and Privacy
        Policy.
      </Text>

      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E9E9E9",
        }}
      ></Box>
      <Text
        fontSize={14}
        fontWeight={600}
        color="#353535"
        style={{ marginTop: 10, marginBottom: 10, textAlign: "center" }}
      >
        Choose Language
      </Text>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: 5,
        }}
      >
        <Link
          component="button"
          style={{
            marginRight: 25,
            fontSize: 16,
          }}
          className={!clicksubmit ? "underline" : "non-underline"}
          onClick={() => {
            handleLanguageChange("en");
          }}
        >
          English
        </Link>
        <Link
          component="button"
          style={{ fontSize: 16 }}
          className={clicksubmit ? "underline" : "non-underline"}
          onClick={() => {
            handleLanguageChange("es");
          }}
        >
          Español
        </Link>
      </Box>
    </div>
  );
};

export default SigninForm;
