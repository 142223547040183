import React, { useState } from "react";
import "./Services.css";
import "../../App.css";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import lng from "../../config/i18";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Instance } from "@popperjs/core";
import ErrorHandlingApi from "../../utilities/ErrorHandlingAPI";
import UploadNames from "../../utilities/UploadNames";
import { useTranslation } from "react-i18next";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MapIcon from "@mui/icons-material/Map";
import AvTimerIcon from "@mui/icons-material/AvTimer";


interface SubmitFormState  {
  fullname: string;
  age: string;
  address: string;
  services: string;
  location: string;
  telephone: string;
  reponsetype: string;
  availability: string;
}


const Services = () => {
  const { t } = useTranslation();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "#1A2027" : "rgb(233 233 233 / 43%)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));
  const [open, setOpen] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [delpop, setPopDel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [uploadDataSubcategories, setUploadDataSubcategories] = useState(false);
  const [failedMsg, setfailedMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState("Message");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [buttonEnable, setButtonEnable] = useState(false);
  const [categoryData, setState] = useState({
    name: "",
    description: "",
    url: "",
    iconMenu: "",
    subcategories: [{ name: "", description: "", url: "", iconMenu: "" }],
    note: "",
    tooltip: "",
  });
  const [formData, setFormData] = useState<SubmitFormState> ({
    fullname: '',
    age: '',
    address: '',
    services: '',
    location: '',
    telephone: '',
    reponsetype: '',
    availability: ''
  })
  const [typeselected, setDataUploaded] = useState("addNew");
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);
  const lang: any = lng;
  let uploaddata: any = t("upload-data", { returnObjects: true });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };
  const handleClickOpen = (data: any) => {
    if (data.subcategories && data.subcategories.length) {
      setUploadDataSubcategories(true);
      setState({
        name: data.name,
        description: data.description,
        url: data.url,
        iconMenu: data.iconMenu,
        subcategories: data.subcategories,
        note: data.note,
        tooltip: data.tooltip,
      });
    } else {
      setUploadDataSubcategories(false);
      setState({
        name: data.name,
        description: data.description,
        url: data.url,
        iconMenu: data.iconMenu,
        note: data.note,
        subcategories: [],
        tooltip: data.tooltip,
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    setDataUploaded("addNew");
    setButtonEnable(false);
    successShow(false);
    failedShow(false);
    setOpen(false);
  };
  const successShow = (val?: any) => {
    setSuccessMsg(val);
  };
  const failedShow = (val?: any) => {
    setfailedMsg(val);
  };
  const responseValidation = (data: any) => {
    const refs = document.querySelectorAll("input[accept='.xlsx']");
    refs.forEach((element: any, index) => {
      element.value = null;
    });
    if (data?.isSuccess === true) {
      successShow(true);
    } else {
      failedShow(true);
    }
    setButtonEnable(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debugger;
    const {name, value} = e.target;
    setFormData(prevData => ({...prevData, [name]: value}))
  }
  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    debugger;
    setLoader(true);
    try {
      const response = await axios.post('http://localhost:8080/apiv1/signup', formData);
      console.log(response); //Will result in an error because the above endpoint doesn't exist yet
      setLoader(false);
    }catch (error) {
      const msg = ErrorHandlingApi(error);
      console.error(error);
      setErrorMsg(msg);
      setLoader(false);
    }
  };

  const renderDialog = () => {
    if (uploadDataSubcategories) {
      return (
        <div>
          <Typography
            component="p"
            style={{
              fontSize: 13,
              color: "#ff0000",
              marginBottom: 10,
              fontStyle: "italic",
            }}
          >
            {t("note")}: {categoryData?.note}
          </Typography>

          <Grid
            container
            style={{ justifyContent: "flex-start", marginTop: 10 }}
          >
            {categoryData.subcategories.map((e, i) => (
              <Grid
                item
                xs={6}
                className="categories"
                style={{ textAlign: "center" }}
              >
                <Item
                  key={i}
                  style={{ alignItems: "flex-start" }}
                  onClick={() => {
                    handleClickOpen(e);
                  }}
                >
                  <div>
                    <img
                      src={e.iconMenu}
                      style={{ width: 60, marginRight: 20, height: 60 }}
                    />
                  </div>
                  <div>
                    <Typography component="h2" style={{ fontWeight: "bolder" }}>
                      {e?.name}
                    </Typography>
                    <p>{e?.description}</p>
                  </div>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <Typography
            component="h3"
            style={{ fontWeight: "bolder", color: "#910C86" }}
          >
            {t("fillinformation")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="name" label="Full Name" variant="standard" onChange={handleChange}  value={formData.fullname} required />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AvTimerIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="age" label="Age" variant="standard" onChange={handleChange} value={formData.age} required/>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <MapIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="address" label="Address" variant="standard" onChange={handleChange} value={formData.address} required />
          </Box>

          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <CheckCircleIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="services" label="Services" variant="standard" onChange={handleChange} value={formData.services} required/>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LocationOnIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="location" label="Location" variant="standard" onChange={handleChange} value={formData.location} required/>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AddIcCallIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="telephone"
              label="Telephone (WhatsApp No.)"
              variant="standard"
              onChange={handleChange}
              value={formData.telephone} required
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LocalLibraryIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="response" label="Response Type" variant="standard"  onChange={handleChange} value={formData.reponsetype} required/>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <EventAvailableIcon
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField id="available" label="Availability" variant="standard" onChange={handleChange} value={formData.availability} required />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "10px" }}
              
            >
              Submit
            </Button>
          
          </Box>
        </form>
      );
    }
  };
  return (
    <div
      style={{ marginLeft: 120, marginTop: 20, width: "calc(100% - 140px)" }}
    >
      <Header />
      <Typography
        component="h2"
        mt={3}
        mb={2}
        style={{
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: 600,
          lineHeight: "normal",
        }}
      >
        {t("services")}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: 20, display: "flex" }}>
        {uploaddata.map((el: any, i: number) => (
          <Grid item xs={4} className="categories">
            <Item
              key={i}
              style={{ paddingBottom: 20, alignItems: "flex-start" }}
              onClick={() => {
                handleClickOpen(el);
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={el.iconMenu} style={{ width: 60, height: 60 }} />
                <Typography
                  component="h2"
                  style={{
                    fontWeight: "bolder",
                    marginTop: 5,
                    textWrap: "nowrap",
                  }}
                >
                  {el.name}
                </Typography>
              </div>
              <div style={{ display: "flex", marginLeft: 20 }}>
                <p style={{ marginRight: 10, marginBottom: 0 }}>
                  {el.description}
                </p>
                <AddCircleIcon />
              </div>
            </Item>
          </Grid>
        ))}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          style={{minWidth:"500px"}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="upload-dialog"
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
            <Typography component="p" style={{ fontWeight: "bolder" }}>
              {t("selectedoption")}{" "}
              <span style={{ color: "#c30459" }}>{categoryData.name}</span>{" "}
              <br />
            </Typography>{" "}
            <div>
              <CloseIcon
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              ></CloseIcon>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControl >{renderDialog()}</FormControl>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
      <Footer />
    </div>
  );
};


export default Services;
