import React from "react";
import Divider from "@mui/material/Divider";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";

import "./Terms.css";

const Terms = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className="terms-page">
        <nav className="navbar">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="logoContainer"
          >
            <a href="/" style={{ display: "flex", alignItems: "center" }}>
              <img className="menu-logo" alt="menu-logo" src="/logo.svg" />
              <Divider orientation="vertical" variant="middle" />
              <img
                style={{ width: 150, height: 58, marginRight: 10 }}
                className="menu-logo"
                alt="menu-logo"
                src="/client.svg"
              />
            </a>
          </Box>
        </nav>
        <div
          className="privacy-heto-section
"
        >
          <header>
            <h1 className="headText">Terms of Service</h1>
          </header>
          <div className="hero-hired-wrap">
            <div
              className="term-section"
              style={{
                textAlign: "justify",
              }}
            >
              <p>
                The MVMH team wants to keep a simple and straightforward
                approach to all aspects of our service. Of course some legal
                language is required to protect both You and the companies that
                will be working with You. The column on the left is the legal
                language needed to protect You. The column on the right is a
                simplified – straightforward explanation that holds no legality.
                You should read, fully understand and agree to our Terms of
                Service before using MVMH .
              </p>
            </div>
          </div>
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Introduction</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Reading this page is important. We want You to be clear and
                  fully understand the Terms of Service listed within. This
                  applies to any service provided by MVMH . “ MVMH ”, applies to
                  MVMH Inc, the MVMH app, website and any affiliated services or
                  business. If You do not meet or follow the terms, You are
                  unable to use the MVMH services.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  You must agree with the Terms or and follow the rules. If You
                  don’t, then You can not use MVMH .
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Binding Agreement</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  These terms act as an Agreement between You and MVMH . If You
                  choose to use the MVMH services than You agree to the terms,
                  and MVMH will abide to the same terms. These terms are subject
                  to change.
                </p>
              </div>
              <div
                className="term-section  second"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p> MVMH agrees to these Terms too!</p>
              </div>
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Definitions</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  The following definitions apply to this Agreement: <br />
                  Clients (also considered a User) – Company seeking to fill
                  employment openings using MVMH services. <br />
                  Applicant (also considered a User) -Individual job Applicants
                  using MVMH services to seek employment. <br /> Agreement – The
                  entire text, regulations, guidelines and rules outlined in
                  this Terms of Service Agreement <br /> You (also considered a
                  User) – The person agreeing with these Terms of Service <br />
                  User- Any and all entities who are actively or previously
                  accessing any aspect of the MVMH website, application or
                  services.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  {" "}
                  You must agree with the Terms or and follow the rules. If You
                  don’t, then You can not use MVMH .
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Acceptable Use of Services</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  MVMH is responsible for defining Acceptable Use of Services.
                  No other entity may hold this right. A Limited Access License
                  for use of, and or any involvement with, MVMH is granted to
                  Users who are 18 years of age and older unless under the
                  supervision of a legal, responsible adult according to the law
                  of Your jurisdiction. This license grants a limited access
                  which may be terminated based on the guidelines defined in
                  these Terms. You agree to use services as defined and lawful.
                  Users are fully responsible for any information or content
                  they provide or upload and associated repercussion. Users will
                  only provide content that is legal, and in no violation of
                  copyright and or proprietary rights. MVMH has the right to
                  terminate or suspend services provided at any time without
                  reason.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  {" "}
                  We created this site for the reasons listed. Use the site for
                  how it was intended – aka no funny business.
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Allocation of Responsibility</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Users are responsible for their activities and use of the
                  service provided. MVMH may monitor and filter these activities
                  at any time with no obligation to the Users or services
                  offered.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  {" "}
                  We would love to see everything and be everywhere, but that’s
                  just crazy. In those cases, we are not responsible.
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>{" "}
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Allocation of Responsibility</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Users are responsible for their activities and use of the
                  service provided. MVMH may monitor and filter these activities
                  at any time with no obligation to the Users or services
                  offered.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  {" "}
                  We would love to see everything and be everywhere, but that’s
                  just crazy. In those cases, we are not responsible.
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">No Endorsements by MVMH</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Content and services created by Users or third party providers
                  are not to be considered endorsed by MVMH . Users Links to
                  third party sites are not maintained nor represented by MVMH .
                  Accessing third party sites through MVMH must be done at Your
                  own risk.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p> Just because it’s there, doesn’t mean we agree with it.</p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Right to Representation</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  MVMH candidates must pass an authorization gateway allowing
                  MVMH to be their exclusive representative for any position
                  prior to application submission. Users are not able to apply
                  without this authorization Agreement.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p> MVMH is your representative.</p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Payment Terms</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Invoices are generated upon job start date and payable Net 45.
                  For any reason a candidate does not remain employed (quit or
                  terminated) beyond 45 days, MVMH will replace the candidate
                  with more appropriate candidate at no additional cost. If
                  candidate has remained employed by day 45, the invoice is due
                  on that date. No other payment is due for that candidate
                  within the initial 45 days of employment. Disputes are handled
                  by MVMH. Users associated with payment disputes agree to pay
                  reasonable and customary legal costs. Payment amounts due are
                  approximate calculations based on job descriptions, range
                  offered, negotiated amounts based on final amount and
                  additional factors. In the event that a Client does not pay
                  MVMH, according to the agreed terms, all other Users whom
                  payment is due, agree to forfeit said payment. MVMH referral
                  rewards are limited to a single referral source per candidate.
                  Preliminary employment period must be completed to be eligible
                  for referral rewards. Preliminary employment period consists
                  of 160 hours for contract positions and 90 days for full-time
                  employment. “Share & Earn” rewards are issued after 80
                  completed hours of employment. MVMH reserves the right to
                  change or modify its reward system at anytime. MVMH will not
                  terminate a User with intent of payment prevention. User
                  agrees to payment terms within MVMH service Agreement.
                </p>
              </div>
              <div
                className="term-section second"
                style={{
                  width: "50%",
                  justifyContent: "space-around",
                  alignItems: "center",

                  textAlign: "justify",
                }}
              >
                <p>
                  {" "}
                  Payments to MVMH should be made on time. Payments amounts to
                  Users from MVMH are estimated amounts.
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
        </div>
        <footer className="footer">
          <div className="copyright">
            <div className="footerText">
              <a href="/terms">Terms of use</a>
              <span>|</span>
              <a href="/privacy">Privacy Policy</a>
              <span>|</span>
              <a href="#" onClick={handleOpen}>
                Contact Us
              </a>
              <Modal
                open={open}
                onClose={handleClose}
                className="modal-contact-us"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={(theme) => ({
                  [theme.breakpoints.only("xs")]: {
                    borderRadius: 70,
                  },
                })}
              >
                <Box className="modal-container">
                  <div className="modal-header">
                    <Typography className="modal-title">Contact Us</Typography>
                    <ClearIcon className="close-icon" onClick={handleClose} />
                  </div>
                  <div className="modal-content">
                    <Typography
                      id="modal-modal-description"
                      sx={{ mb: 2 }}
                      className="modal-description"
                    >
                      Please write to us on link{" "}
                      <a
                        href="mailto:support@myvoicemyhelp.com"
                        style={{ color: "black ", textDecoration: "none" }}
                      >
                        support@myvoicemyhelp.com
                      </a>{" "}
                      We typically respond within 24 hours on all business days.
                    </Typography>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="footerText">
              &copy; {new Date().getFullYear()} MVMH Inc. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Terms;
