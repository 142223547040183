import React from "react";
import { Grid, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
// import { useTranslation } from "react-i18next";

import "./Footer.css"; // Import the CSS file

const LoginFooter = () => {
  // const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
          fontSize: 13,
        }}
      >
        <Grid item xs={8} style={{ display: "flex", gap: 20 }}>
          <Link href="/terms" style={{ textDecoration: "None", color: "#000" }}>
            {" "}
            Terms of use
          </Link>{" "}
          |
          <Link
            href="/privacy"
            style={{ textDecoration: "None", color: "#000" }}
          >
            {" "}
            Privacy Policy
          </Link>{" "}
          |
          <Link
            href="#"
            style={{ textDecoration: "None", color: "#000" }}
            onClick={handleOpen}
          >
            Contact Us
          </Link>
          <Modal
            open={open}
            onClose={handleClose}
            className="modal-contact-us"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                borderRadius: 70,
              },
            })}
          >
            <Box className="modal-container">
              <div className="modal-header">
                <Typography className="modal-title">Contact Us</Typography>
                <ClearIcon className="close-icon" onClick={handleClose} />
              </div>
              <div className="modal-content">
                <Typography
                  id="modal-modal-description"
                  sx={{ mb: 2 }}
                  className="modal-description"
                >
                  Please write to us on link{" "}
                  <a
                    href="mailto:support@myvoicemyhelp.com"
                    style={{ color: "black ", textDecoration: "none" }}
                  >
                    support@myvoicemyhelp.com
                  </a>{" "}
                  We typically respond within 24 hours on all business days.
                </Typography>
              </div>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={4}>
          Copyright MVMH Inc. 2024, all rights reserved.
        </Grid>
      </Grid>
    </>
  );
};

export default LoginFooter;
