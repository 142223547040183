export const MenuListItems = [
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Dashboard",
    tname: "Panel de control de la PMO",
    url: "/dashboard",
    toolTip: "Dashboard",
    displayOrder: 1,
    menuImage: "pmo.svg",
  },
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: false,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "",
    cssClass: "admin-css",
    name: "Home",
    tname: "Hogar",
    url: "/home",
    toolTip: "Home",
    displayOrder: 2,
    menuImage: "home.svg",
  },
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Services",
    tname: "Servicios",
    url: "/services",
    toolTip: "Services",
    displayOrder: 3,
    menuImage: "services.svg",
  },
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Analytics",
    tname: "Analítica",
    url: "/analytics",
    toolTip: "Analytics",
    displayOrder: 4,
    menuImage: "analytics.svg",
  },
];
