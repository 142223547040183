import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./locales/en/translation.json";
import Spanish from "./locales/es/translation.json";

const resources = {
  en: {
    translation: English,
  },
  es: {
    translation: Spanish,
  },
};
i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
