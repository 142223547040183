import React, { FC, CSSProperties } from "react";
import "./Header.css";
import Navigation from "../sidebar/Navigation";
import { useNavigate } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/Lock";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Button,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Link,
  Select,
} from "@mui/material";
import Text from "../Text";
import i18n from "../../../config/i18";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../../services/storageService";
const Header = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const navigate = useNavigate();
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(true);
  };
  const handleOpenUserNotification = (event: any) => {
    setNotification(true);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(false);
    setNotification(false);
  };
  const language = getLocalStorageItem("languageSelect");

  const handleLanguageChange = (event: any) => {
    setLocalStorageItem("languageSelect", event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <ProSidebarProvider>
        <Navigation />
        <Grid container spacing={2}>
          <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: 10 }}
              className="menu-logo"
              width={150}
              alt="menu-logo"
              src="/logo.svg"
              onClick={() => {
                navigate("/home");
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Select
              labelId="multilingual"
              id="multilingual-select"
              className="language-selection"
              value={language}
              onChange={handleLanguageChange}
              style={{ marginRight: 15, marginLeft: 15, padding: 4 }}
            >
              <MenuItem value={"en"}>US English</MenuItem>
              <MenuItem value={"es"}>HI Hindi</MenuItem>
            </Select>

            <Button data-testid="recruiterAvtar" onClick={handleOpenUserMenu}>
              <Avatar
                className="user-profiles"
                sx={{
                  height: 35,
                  width: 35,
                  border: "none",
                }}
              >
                AT
              </Avatar>
              <KeyboardArrowDownIcon
                style={{ color: "#6A6A6A", marginLeft: 5 }}
              />
            </Button>
            <Menu
              open={anchorElUser}
              onClose={handleCloseUserMenu}
              className="user-option"
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                data-testid="recruiterUplaodPhoto"
                className="menuItems userInfo"
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <Box>
                  <Typography className="listText">User</Typography>

                  <Typography className="listText">User@gmail.com</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                data-testid="recruiterUplaodPhoto"
                className="menuItems"
              >
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <Typography className="listText">Change Password</Typography>
              </MenuItem>

              <MenuItem className="menuItems">
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <Typography className="listText logout">Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </ProSidebarProvider>
    </div>
  );
};

interface NotificationTileProps {
  style?: CSSProperties;
  initials: string;
  title: string;
  position: string;
  date: string;
}

const NotificationTile: FC<NotificationTileProps> = (props) => {
  return (
    <Box
      sx={{
        height: 70,
        width: 280,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 1.5,
        paddingTop: 1.5,
        borderBottom: "1px solid #E9E9E9",
        ...props.style,
      }}
    >
      <Avatar
        sx={{
          height: 35,
          width: 35,
          marginLeft: 2,
          marginRight: 1,
          backgroundColor: "#910c86",
        }}
      >
        {props.initials}
      </Avatar>
      <Box>
        <Text>{props.title} </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={10} color={"#B0B0B0"}>
            {props.position}
          </Text>
          <Text fontSize={10} color={"#B0B0B0"}>
            {props.date}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
