import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./components/landingpage/LandingPage";
import PMODashboard from "./components/pmodashboard/PMODashboard";
import Services from "./components/services/Services";
import Analytics from "./components/analytics/Analytics";
import Home from "./components/home/Home";
import PageNotFound from "./components/pagenotfound/PageNotFound";
import SignIn from "./components/signin/SignIn";
import Terms from "./components/footerlinks/Terms";
import Privacy from "./components/footerlinks/Privacy";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/home" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/dashboard" element={<PMODashboard />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
