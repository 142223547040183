const ErrorHandlingApi = (error: any) => {
  let msg = error.response.data
    ? error.response.data?.messageList?.message1
    : error.response.data.errors[0];
  if (
    !msg &&
    error.response.data.entity &&
    error.response.data.entity?.bulkErrors.length
  ) {
    let labelMsg = "";
    error.response.data.entity.bulkErrors.forEach((obj: any) => {
      labelMsg = labelMsg + "Row No." + obj.key + ": " + obj.value + " , ";
    });
    msg = labelMsg;
  } else if (
    !error.response.data.entity &&
    !msg &&
    error.code !== "ERR_BAD_REQUEST"
  ) {
    msg = error.response.data.errors.FormFile[0];
  }
  if (!msg && error.code === "ERR_BAD_REQUEST") {
    msg = "Bad Request, Please try again.";
  }
  return msg;
};
export default ErrorHandlingApi;
