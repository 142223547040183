import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import { ProSidebarProvider } from "react-pro-sidebar";
import i18n from "./config/i18";
import { I18nextProvider } from "react-i18next";
import { setLocalStorageItem } from "./services/storageService";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const defaultTheme = createTheme();
setLocalStorageItem("languageSelect", "en");
root.render(
  <div style={{ fontFamily: "Roboto" }}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </I18nextProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
